<template>
    <div class="test">
        <!--  -->

        <section class="news-detail pt-lg-0 pt-5 pb-lg-25 pb-sm-7 pb-20">
            <section class="">
                <div class="container p-0">
                    <div class="d-flex align-items-center">
                        <a
                            href="/"
                            class="text-decoration-none mr-1"
                            style="color: black"
                        >
                            {{ isEnglish ? "Home" : "Anasayfa" }}
                        </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a
                            href="products"
                            class="text-decoration-none text-danger"
                        >
                            {{ isEnglish ? "Products" : "Ürünler" }}
                        </a>
                    </div>
                </div>
            </section>
            <section class="mt-5 mb-5">
                <div id="menu-row" class="container-fluid">
                    <div class="row">
                        <!-- Menu Başlangıç  -->
                        <div class="col-12 col-md-4">
                            <div
                                class="text-center text-white p-2"
                                style="
                                    background-color: #ef353d;
                                    border-radius: 5px 5px 0px 0px;
                                "
                            >
                                <span v-if="isEnglish">PRODUCT CATEGORIY</span>
                                <span v-else>ÜRÜN KATEGORİLERİ</span>
                            </div>
                            <div
                                class="filter-menu"
                                id="filter-menu"
                                style="
                                    background-color: #f8f8f8;
                                    border-radius: 0px 0px 5px 5px;
                                    margin-bottom: 85px !important;
                                "
                            >
                                <ul class="list-style-none p-0">
                                    <li
                                        v-for="(menu, index) in menus"
                                        :key="index"
                                        class="menu-list-type"
                                    >
                                        <div
                                            class="d-flex justify-content-between"
                                        >
                                            <a
                                                :href="
                                                    isEnglish
                                                        ? menu.urlEn
                                                        : menu.url
                                                "
                                                :class="{
                                                    'link-active': isActive(
                                                        isEnglish
                                                            ? menu.urlEn
                                                            : menu.url
                                                    ),
                                                }"
                                                class="text-decoration-none menu-list-type"
                                                @click="
                                                    setActive(
                                                        isEnglish
                                                            ? menu.urlEn
                                                            : menu.url
                                                    )
                                                "
                                            >
                                                {{
                                                    isEnglish
                                                        ? menu.nameEn
                                                        : menu.name
                                                }}
                                            </a>
                                            <!-- <i :id="'menu-plus' + (index + 1)" @click="toggleMenu(index + 1)"
                                            :class="['fas','d-flex align-items-center', 'plus-color', { 'fa-plus': !isMenuOpen[index], 'fa-minus': isMenuOpen[index] }]"></i> -->
                                            <span
                                                :id="'menu-plus' + (index + 1)"
                                                @click="toggleMenu(index + 1)"
                                                :class="['plus-color']"
                                                style="font-size: 30px"
                                            >
                                                {{
                                                    isMenuOpen[index]
                                                        ? "-"
                                                        : "+"
                                                }}
                                            </span>
                                        </div>
                                        <ul
                                            :id="'open-menu-' + (index + 1)"
                                            class="list-style-none"
                                            :class="{
                                                'open-menu-passive':
                                                    !isMenuOpen[index],
                                                'open-menu-active':
                                                    isMenuOpen[index],
                                            }"
                                        >
                                            <li
                                                v-for="(
                                                    submenu, subIndex
                                                ) in menu.submenus"
                                                :key="subIndex"
                                                class="menu-list-type"
                                            >
                                                <a
                                                    :class="{
                                                        'link-active': isActive(
                                                            isEnglish
                                                                ? submenu.urlEn
                                                                : submenu.url
                                                        ),
                                                    }"
                                                    :href="
                                                        isEnglish
                                                            ? submenu.urlEn
                                                            : submenu.url
                                                    "
                                                    class="text-decoration-none menu-list-type"
                                                >
                                                    {{
                                                        isEnglish
                                                            ? submenu.nameEn
                                                            : submenu.name
                                                    }}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Menu Bitiş  -->
                        <div class="col-12 col-md-8">
                            <div class="row align-items-center">
                                <div class="col-12" style="padding-left: 15px; padding-right: 15px;">
                                    <div
                                class="  text-center text-white p-2"
                                style="
                                    background-color: #ef353d;
                                    border-radius: 5px 5px 0px 0px;
                                "
                            >
                                <h1 style="font-size: 16px;margin: 0px" v-if="isEnglish">PRODUCTS</h1>
                                <h1 style="font-size: 16px;margin: 0px" v-else>ÜRÜNLER</h1>
                            </div>
                        </div>
                                <div
                                    v-for="(product, index) in products"
                                    :key="index"
                                    class="col-12 col-md-4 mb-6 banner-product"
                                >
                                    <div class="product-banner">
                                        <a
                                            :href="
                                                isEnglish
                                                    ? product.urlEn
                                                    : product.url
                                            "
                                            class="text-decoration-none product-link d-flex align-items-center text-center justify-content-center flex-column"
                                        >
                                            <!-- <img :src="product.image" class="img-filter" :alt="product.alt"> -->
                                            <div
                                                class="w-100 pr-slider d-flex justify-content-between"
                                                :data-src="
                                                    product.default_slider
                                                "
                                                :style="{
                                                    'background-image':
                                                        'url(' +
                                                        product.default_slider +
                                                        ')',
                                                }"
                                            >
                                                <div
                                                    class="pr-item"
                                                    v-for="(
                                                        slider, index
                                                    ) in product.slider"
                                                    :key="index"
                                                    :data-src="slider.image"
                                                ></div>
                                            </div>
                                            <p
                                                style="
                                                    background-color: #f8f8f8;
                                                    padding: 10px;
                                                    width: 100%;
                                                    margin-bottom: 0px;
                                                    border-radius: 0px 0px 5px
                                                        5px;
                                                "
                                            >
                                                {{
                                                    isEnglish
                                                        ? product.nameEn
                                                        : product.name
                                                }}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split("/")[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === "en"; // İlk segment 'en' ise true döner
        return {
            isMenuOpen: Array(6).fill(false),
            isEnglish: isEnglish,
            products: [
                {
                    name: "Fren Grubu",
                    nameEn: "Brake Group",
                    url: "fren-grubu",
                    urlEn: "brake-group",
                    image: "/images/fren-grubu.jpg",
                    alt: "Fren Grubu Image",
                    default_slider: "/images/fren-kampanasi-nedir.png",
                    slider: [
                        {
                            image: "/images/fren-kampanasi-nedir.png",
                        },
                        {
                            image: "/images/fren-diski-nedir.png",
                        },
                        {
                            image: "/images/kampana-balata-nedir.png",
                        },
                        {
                            image: "/images/disk-balata-nedir.png",
                        },
                        {
                            image: "/images/fren-ayar-kolu-nedir.png",
                        },
                        {
                            image: "/images/fren-korugu-nedir.png",
                        },
                        {
                            image: "/images/fren-pabucu.png",
                        },
                        {
                            image: "/images/fren-kaliperi.png",
                        },
                        {
                            image: "/images/poyra.png",
                        },
                    ],
                },
                {
                    name: "Havalı Fren Grubu",
                    nameEn: "Air Brake System",
                    url: "havali-fren-grubu",
                    urlEn: "air-brake-system",
                    image: "/images/havali-fren-grubu.jpg",
                    alt: "Havalı Fren Grubu Image",
                    default_slider: "/images/hava-kurutucusu-kartusu.png",
                    slider: [
                        {
                            image: "/images/hava-kurutucusu-kartusu.png",
                        },
                        {
                            image: "/images/hava-kurutucusu.png",
                        },
                        {
                            image: "/images/valfler.png",
                        },
                    ],
                },
                {
                    name: "Motor Grubu",
                    nameEn: "Motor Group",
                    url: "motor-grubu",
                    urlEn: "motor-group",
                    image: "/images/motor-grubu.jpg",
                    alt: "Motor Grubu Image",
                    default_slider: "/images/yakit-pompasi.png",
                    slider: [
                        {
                            image: "/images/yakit-pompasi.png",
                        },
                        {
                            image: "/images/yag-pompasi.png",
                        },
                        {
                            image: "/images/su-pompasi.png",
                        },
                    ],
                },
                {
                    name: "Şanzıman Grubu",
                    nameEn: "Transmission Group",
                    url: "sanziman-grubu",
                    urlEn: "transmission-group",
                    image: "/images/sanziman-grubu.jpg",
                    alt: "Şanzıman Grubu Image",
                    default_slider: "/images/debriyaj-diski.png",
                    slider: [
                        {
                            image: "/images/debriyaj-diski.png",
                        },
                        {
                            image: "/images/debriyaj-merkezi.png",
                        },
                        {
                            image: "/images/debriyaj-baskisi.png",
                        },
                        {
                            image: "/images/debriyaj-catali.png",
                        },
                        {
                            image: "/images/debriyaj-guclendiricisi.png",
                        },
                        {
                            image: "/images/debriyaj-rulmani.png",
                        },
                    ],
                },
                {
                    name: "Treyler Grubu",
                    nameEn: "Trailer Group",
                    url: "treyler-grubu",
                    urlEn: "trailer-group",
                    image: "/images/treyler-grubu.jpg",
                    alt: "Treyler Grubu Image",
                    default_slider: "/images/besinci-teker.png",
                    slider: [
                        {
                            image: "/images/besinci-teker.png",
                        },
                        {
                            image: "/images/dorse-ayagi.png",
                        },
                    ],
                },
                {
                    name: "Sensör Grubu",
                    nameEn: "Sensor Group",
                    url: "sensor-grubu",
                    urlEn: "sensor-group",
                    image: "/images/sensor-grubu.jpg",
                    alt: "Sensör Grubu Image",
                    default_slider: "/images/abs-tekerlek-hiz-sensoru.png",
                    slider: [
                        {
                            image: "/images/abs-tekerlek-hiz-sensoru.png",
                        },
                    ],
                },
            ],
            menus: [
                {
                    name: "FREN GRUBU",
                    nameEn: "BBRAKE GROUP",
                    url: "fren-grubu",
                    urlEn: "brake-group",
                    submenus: [
                        {
                            name: "FREN KAMPANASI",
                            nameEn: "BRAKE DRUM",
                            url: "fren-kampanasi",
                            urlEn: "brake-drum",
                        },
                        {
                            name: "FREN DİSKİ",
                            nameEn: "BRAKE DİSC",
                            url: "fren-diski",
                            urlEn: "brake-disc",
                        },
                        {
                            name: "KAMPANA BALATA",
                            nameEn: "BRAKE LİNİNG",
                            url: "kampana-balata",
                            urlEn: "brake-lining",
                        },
                        {
                            name: "DİSK BALATA",
                            nameEn: "BRAKE PAD",
                            url: "disk-balata",
                            urlEn: "brake-pad",
                        },
                        {
                            name: "FREN AYAR KOLU",
                            nameEn: "SLACK ADJUSTER",
                            url: "fren-ayar-kolu",
                            urlEn: "slack-adjuster",
                        },
                        {
                            name: "FREN KÖRÜĞÜ",
                            nameEn: "BRAKE CHAMBER",
                            url: "fren-korugu",
                            urlEn: "brake-chamber",
                        },
                        {
                            name: "FREN PABUCU",
                            nameEn: "BRAKE SHOE",
                            url: "fren-pabucu",
                            urlEn: "brake-shoe",
                        },
                        {
                            name: "FREN KALİPERİ",
                            nameEn: "BRAKE CALİPER",
                            url: "fren-kaliperi",
                            urlEn: "brake-caliper",
                        },
                        {
                            name: "POYRA",
                            nameEn: "WHELLHUB",
                            url: "poyra",
                            urlEn: "wheelhub",
                        },
                    ],
                },
                {
                    name: "HAVALI FREN GRUBU",
                    nameEn: "AIR BRAKE SYSTEM",
                    urlEn: "air-brake-system",
                    url: "havali-fren-grubu",
                    submenus: [
                        {
                            name: "HAVA KURUTUCUSU KARTUŞU",
                            nameEn: "AIR DRYER CARTRIDGE",
                            urlEn: "air-dryer-cartridge",
                            url: "hava-kurutucusu-kartusu",
                        },
                        {
                            name: "HAVA KURUTUCUSU",
                            nameEn: "AIR DRYER",
                            urlEn: "air-dryer",
                            url: "hava-kurutucusu",
                        },
                        {
                            name: "VALFLER",
                            nameEn: "VALVES",
                            urlEn: "valves",
                            url: "valfler",
                        },
                    ],
                },
                {
                    name: "MOTOR GRUBU",
                    nameEn: "MOTOR GROUP",
                    urlEn: "motor-group",
                    url: "motor-grubu",
                    submenus: [
                        {
                            name: "YAKIT POMPASI",
                            nameEn: "FUL PUMP",
                            urlEn: "ful-pump",
                            url: "yakit-pompasi",
                        },
                        {
                            name: "YAĞ POMPASI",
                            nameEn: "OIL PUMP",
                            urlEn: "oil-pump",
                            url: "yag-pompasi",
                        },
                        {
                            name: "SU POMPASI",
                            nameEn: "WATER PUMP",
                            urlEn: "water-pump",
                            url: "su-pompasi",
                        },
                    ],
                },
                {
                    name: "ŞANZIMAN GRUBU",
                    nameEn: "TRANSMISSION GROUP",
                    urlEn: "transmission-group",
                    url: "sanziman-grubu",
                    submenus: [
                        {
                            name: "DEBRİYAJ DİSKİ",
                            nameEn: "CLUTCH DISC",
                            urlEn: "clutch-disc",
                            url: "debriyaj-diski",
                        },
                        {
                            name: "DEBRİYAJ MERKEZİ",
                            nameEn: "CLUTCH MASTER",
                            urlEn: "clutch-master",
                            url: "debriyaj-merkezi",
                        },
                        {
                            name: "DEBRİYAJ BASKISI",
                            nameEn: "CLUTCH PRESSURE",
                            urlEn: "clutch-pressure",
                            url: "debriyaj-baskisi",
                        },
                        {
                            name: "DEBRİYAJ ÇATALI",
                            nameEn: "CLUTCH FORK",
                            urlEn: "clutch-fork",
                            url: "debriyaj-catali",
                        },
                        {
                            name: "DEBRİYAJ GÜÇLENDİRİCİSİ",
                            url: "debriyaj-guclendiricisi",
                            nameEn: "CLUTCH BOOSTER",
                            urlEn: "clutch-booster",
                        },
                        {
                            name: "DEBRİYAJ RULMANI",
                            nameEn: "CLUTCH BEARING",
                            urlEn: "clutch-bearing",
                            url: "debriyaj-rulmani",
                        },
                    ],
                },
                {
                    name: "TREYLER GRUBU",
                    nameEn: "TRAILER GROUP",
                    urlEn: "trailer-group",
                    url: "treyler-grubu",
                    submenus: [
                        {
                            name: "BEŞİNCİ TEKER",
                            nameEn: "FIFTH WHEEL",
                            urlEn: "fifth-wheel",
                            url: "besinci-teker",
                        },
                        {
                            name: "DORSE AYAĞI",
                            nameEn: "LANDING GEAR",
                            urlEn: "landing-gear",
                            url: "dorse-ayagi",
                        },
                    ],
                },
                {
                    name: "SENSÖR GRUBU",
                    nameEn: "SENSOR GROUP",
                    urlEn: "sensor-group",
                    url: "sensor-grubu",
                    submenus: [
                        {
                            name: "ABS SENSÖR KABLOSU",
                            nameEn: "ABS SENSOR CABLE",
                            urlEn: "abs-sensor-cable",
                            url: "abs-sensor-kablosu",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        toggleMenu(index) {
            this.isMenuOpen[index - 1] = !this.isMenuOpen[index - 1];
        },
        isActive(url) {
            return window.location.href.indexOf(url) !== -1;
        },
        setActive(url) {
            this.menus.forEach((menu) => {
                if (
                    menu.url === url ||
                    (menu.submenus &&
                        menu.submenus.some((submenu) => submenu.url === url))
                ) {
                    menu.isActive = true;

                    if (menu.submenus) {
                        const parentMenu = this.menus.find(
                            (m) =>
                                m.submenus &&
                                m.submenus.some((sub) => sub.url === url)
                        );
                        if (parentMenu) {
                            parentMenu.isActive = true;
                        }
                    }
                } else {
                    menu.isActive = false;
                    if (menu.submenus) {
                        menu.submenus.forEach((submenu) => {
                            submenu.isActive = false;
                        });
                    }
                }
            });
        },
    },
    mounted() {
        $(document).on("mouseenter", ".pr-item", function () {
            var prSlider = $(this).closest(".pr-slider");
            var newBackgroundImage = $(this).data("src");
            prSlider.css("background-image", "url(" + newBackgroundImage + ")");
        });

        $(document).on("mouseleave", ".pr-slider", function () {
            var varsayilan = $(this).data("src");
            $(this).css("background-image", "url(" + varsayilan + ")");
        });

        const isFrenGrubuPage =
            window.location.href.indexOf("fren-grubu") !== -1;
        if (isFrenGrubuPage) {
            const item28 = document.getElementById("item-28");
            if (item28) {
                item28.classList.add("link-active");
            }
        }

        const itemElements = document.querySelectorAll('[id^="item-"]');
        if (itemElements) {
            itemElements.forEach((item) => {
                item.addEventListener("click", () => {
                    itemElements.forEach((el) =>
                        el.classList.remove("link-active")
                    );
                    item.classList.add("link-active");
                });
            });
        }

        const productCategory = document.getElementById("product-category");
        const filterMenu = document.getElementById("filter-menu");

        if (productCategory && filterMenu) {
            const productCategoryTop = productCategory.offsetTop;
            const productCategoryHeight = productCategory.offsetHeight;
            const filterMenuTop = filterMenu.offsetTop;
            const filterMenuHeight =
                filterMenu.offsetHeight + productCategoryHeight;
            const filterMenuHeightPosition =
                filterMenuHeight - filterMenuHeight;

            window.addEventListener("scroll", () => {
                const windowHeight = window.innerHeight;
                const scrollPosition = window.scrollY;

                if (
                    window.innerWidth > 900 &&
                    scrollPosition >= filterMenuTop &&
                    scrollPosition <= filterMenuTop + filterMenuHeightPosition
                ) {
                    const parentWidth = filterMenu.parentElement.offsetWidth;
                    filterMenu.style.position = "fixed";
                    filterMenu.style.top = "0";
                    filterMenu.style.width = `${parentWidth}px`;
                } else {
                    filterMenu.style.position = "relative";
                    filterMenu.style.padding = "0";
                }

                if (
                    window.innerWidth > 900 &&
                    scrollPosition >= filterMenuTop + filterMenuHeightPosition
                ) {
                    filterMenu.style.position = "relative";
                    filterMenu.style.top = `${filterMenuHeightPosition}px`;
                }
            });
        }
    },
};
</script>

<style>
.pr-slider {
    height: 300px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;
}

.pr-item {
    width: 100%;
    cursor: pointer;
}

img {
    width: 100%;
}

.carousel-w-60 {
    width: 60% !important;
}

.carousel-w-40 {
    width: 40% !important;
}

p {
    /* color: #212529; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.hr-blue-product {
    width: 15%;
    height: 5px;
    background-color: red;
}

.first-banner-color {
    background-color: #f7f7f7de;
}

.detail-h1 {
    margin: 0 !important;
}

.product-detail-shadow {
    border-left: 8px solid red;
    background-color: #f7f7f7de;
    border-radius: 5px;
    padding: 7px;
}

.filter-menu {
    display: block;
    /* padding: 3rem !important; */
    z-index: 0 !important;
}

#filter-menu {
    z-index: 0 !important;
}

.filter-menu-mobile {
    display: none;
}

.list-style-none {
    list-style: none;
}

.menu-list-type {
    font-size: 15px;
    line-height: 40px;
    font-family: Nexa;
    color: black;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.menu-list-type:hover {
    font-size: 16px;
    color: #4d4d4d;
    transition: all 0.3s ease-in-out;
}

.open-menu-passive {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.open-menu-active {
    max-height: 1000px;
    overflow: hidden;
    opacity: 1;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.link-active {
    font-size: 16px;
    color: #ef353d;
    font-weight: 700;
    border-bottom: 1.5px solid #ef353d;
}

.product-link {
    color: #4d4d4d;
    font-family: Nexa !important;
    font-size: 20px;
}

.product-link:hover {
    color: #ef353d;
}

.product-banner {
    transition: all 0.1s ease-in-out;
    filter: brightness(1);
    width: 100%;
}

.product-banner:hover {
    /* filter: brightness(0.9); */
    background-color: #f8f8f8;
    transition: all 0.1s ease-in-out;
}

/* .nav-link {
    color: #ef353d !important;
    background-color: #f2f2f2 !important;
} */

.margin-block {
    height: 200px;
}

.plus-color {
    color: #ef353d;
}

.banner-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-contain-mobil {
    display: none !important;
}

.mobil-hr {
    width: 100%;
    border: 1px solid gray;
    opacity: 0.3;
}

#product-carousel .owl-prev {
    position: absolute !important;
    left: -25px;
    top: 40%;
    font-size: 45px;
    color: black;
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
}

#product-carousel .owl-prev:hover {
    background-color: transparent;
    color: black;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}

#product-carousel .owl-next {
    position: absolute !important;
    right: -25px;
    top: 40%;
    font-size: 45px;
    color: black;
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
}

#product-carousel .owl-next:hover {
    background-color: transparent;
    color: black;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 900px) {
    .filter-menu {
        margin-bottom: 2rem;
    }

    #menu-row > .row > * {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .banner-product {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .product-contain-mobil {
        display: block !important;
    }

    .hr-blue-product {
        width: 25%;
        height: 5px;
        background-color: blue;
    }

    .hr-yellow-product {
        width: 45%;
        height: 5px;
        background-color: red;
    }

    .product-contain {
        display: none !important;
    }

    #product-carousel .owl-prev {
        left: 0;
    }

    #product-carousel .owl-next {
        right: 0;
    }
}
</style>
