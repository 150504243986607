<template>
    <div class="news-detail pt-lg-0 pt-5 pb-lg-25 pb-sm-7 pb-20">

        <section class="">
            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                        <a href="/" class="text-decoration-none mr-1" style="color: black;"> {{ isEnglish ? "Home"
                            : "Anasayfa" }} </a>
                        <span style="font-size: 30px;" class="mr-1">></span>
                        <a href="products" class="text-decoration-none text-body"> {{ isEnglish ? "Products" :
                            "Ürünler" }} </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'air-brake-system' : 'havali-fren-grubu'"
                            class="text-decoration-none text-body mr-1">{{ isEnglish ? "Air Brake System" :
                                "Havalı Fren Grubu" }}
                        </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'air-dryer-cartridge' : 'hava-kurutucusu-kartusu'" class="text-decoration-none text-danger">{{
                            isEnglish ? "Air Dryer Cartridge" :
                            "Hava Kurutucusu Kartuşu" }}</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="container mt-1 product-contain first-banner-color">
            <div class="row align-items-center justify-content-center p-0 pr-5">
                <div class="col-12 col-md-7 p-5">
                    <div class="d-flex flex-column p-5">
                        <h1 class="detail-h1" v-if="!isEnglish">Hava Kurutucusu Kartuşu </h1>
                        <h1 class="detail-h1" v-else>Air Dryer Cartridge</h1>
                        <div class="hr-blue-product"></div>
                        <p class="mt-3" v-if="!isEnglish">
                            Hava kurutucusu kartuşu, araçların hava fren sistemlerinde kullanılan, havadaki nemi ve
                            diğer kirleticileri temizleyen bir filtredir. Hava kompresöründen gelen basınçlı hava, fren
                            sisteminde kullanılmadan önce hava kurutucusu kartuşundan geçirilir. Bu kartuş, havadaki
                            nemi ve kirleri filtreleyerek fren sistemine temiz ve kuru hava sağlar.
                        </p>
                        <p class="mt-3" v-if="isEnglish">
                            Air dryer cartridge is a filter used in vehicle air brake systems that removes moisture and other contaminants from the air. Compressed air from the air compressor is passed through an air dryer cartridge before being used in the brake system. This cartridge filters out moisture and impurities from the air, providing clean and dry air to the brake system.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-5 p-0">
                    <div class="d-flex justify-content-end">
                        <img src="/images/hava-kurutucusu-kartusu.png" alt="Hava Kurutucusu Kartuşu"
                            style="width: 50%;">
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-5 mb-5">
            <div id="menu-row" class="container-fluid">
                <div class="row">
                    <!-- Menu Başlangıç  -->
                    <!-- <ViewMenu /> -->
                    <ViewMenu />
                    <!-- Menu Başlangıç  -->


                    <ViewContent :details="details" :second_tab="second_tab" :first_tab="first_tab"
                        :tab_is_open="tab_is_open" />

                </div>
                <div class="col-12 pb-5 pt-5">
                    <OtherCategories :slider_items="slider_content" />
                </div>
            </div>
        </section>

        <section class="container-fluid"></section>
    </div>
</template>

<script>
//import Submenuheader from '../../submenuheader.vue';
import ViewMenu from '../ViewMenu';
import ViewContent from "../ViewContent"
import OtherCategories from "../OtherCategories"


export default {
    name: "AbsTekerlekHizSensoru",
    props: [
        "details",
        "second_tab",
        "first_tab",
        "mobile-content",
        "slider_content",
        "tab_is_open"
    ],
    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isEnglish: isEnglish,
        };
    },
    components: { ViewMenu, ViewContent, OtherCategories }
}
</script>

<style lang="scss" scoped></style>