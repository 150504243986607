<template>
    <div class="news-detail pt-lg-0 pt-5 pb-lg-25 pb-sm-7 pb-20">

        <section class="">
            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                        <a href="/" class="text-decoration-none mr-1" style="color: black;"> {{ isEnglish ? "Home"
                            : "Anasayfa" }} </a>
                        <span style="font-size: 30px;" class="mr-1">></span>
                        <a href="products" class="text-decoration-none text-body"> {{ isEnglish ? "Products" :
                            "Ürünler" }} </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'brake-group' : 'fren-grubu'"
                            class="text-decoration-none text-body mr-1">{{ isEnglish ? "Brake Group" :
                                "Fren Grubu" }}
                        </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'brake-shoe' : 'fren-pabucu'" class="text-decoration-none text-danger">{{
                            isEnglish ? "Brake Shoe" :
                            "Fren Pabucu" }}</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="container mt-1 product-contain first-banner-color">
            <div class="row align-items-center justify-content-center p-0 pr-5">
                <div class="col-12 col-md-7 p-5">
                    <div class="d-flex flex-column p-5">
                        <h1 class="detail-h1" v-if="!isEnglish">Fren Pabucu </h1>
                        <h1 class="detail-h1" v-else>Brake Shoe</h1>
                        <div class="hr-blue-product"></div>
                        <p class="mt-3" v-if="!isEnglish">
                            Fren pabucu, frenleme işlemi sırasında fren kampanası ile temas ederek aracın yavaşlamasını
                            ve durmasını sağlayan parçalardan biridir. Genellikle metal veya seramik gibi dayanıklı
                            malzemelerden yapılmış olan fren pabuçları, fren sisteminin etkinliğini artırır.
                        </p>
                        <p class="mt-3" v-if="isEnglish">
                            Brake shoe is one of the parts that allows the vehicle to slow down and stop by making contact with the brake drum during the braking process. Usually made of durable materials such as metal or ceramic, brake shoes increase the effectiveness of the braking system.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-5 p-0">
                    <div class="d-flex justify-content-end">
                        <img src="/images/fren-pabucu.png" alt="Fren Pabucu " style="width: 50%;">
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-5 mb-5">
            <div id="menu-row" class="container-fluid">
                <div class="row">
                    <!-- Menu Başlangıç  -->
                    <!-- <ViewMenu /> -->
                    <ViewMenu />
                    <!-- Menu Başlangıç  -->


                    <ViewContent :details="details" :second_tab="second_tab" :first_tab="first_tab"
                        :tab_is_open="tab_is_open" />

                </div>
                <div class="col-12 pb-5 pt-5">
                    <OtherCategories :slider_items="slider_content" />
                </div>
            </div>
        </section>

        <section class="container-fluid"></section>
    </div>
</template>

<script>
//import Submenuheader from '../../submenuheader.vue';
import ViewMenu from '../ViewMenu';
import ViewContent from "../ViewContent"
import OtherCategories from "../OtherCategories"


export default {
    name: "AbsTekerlekHizSensoru",
    props: [
        "details",
        "second_tab",
        "first_tab",
        "mobile-content",
        "slider_content",
        "tab_is_open"
    ],
    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isEnglish: isEnglish,
        };
    },
    components: { ViewMenu, ViewContent, OtherCategories }
}
</script>

<style lang="scss" scoped></style>