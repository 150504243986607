<template>
    <div class="news-detail pt-lg-0 pt-5 pb-lg-25 pb-sm-7 pb-20">

        <section class="">
            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                        <a href="/" class="text-decoration-none mr-1" style="color: black;"> {{ isEnglish ? "Home"
                            : "Anasayfa" }} </a>
                        <span style="font-size: 30px;" class="mr-1">></span>
                        <a href="products" class="text-decoration-none text-body"> {{ isEnglish ? "Products" :
                            "Ürünler" }} </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'brake-group' : 'fren-grubu'"
                            class="text-decoration-none text-body mr-1">{{ isEnglish ? "Brake Group" :
                                "Fren Grubu" }}
                        </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'brake-lining' : 'kampana-balata'" class="text-decoration-none text-danger">{{
                            isEnglish ? "Brake Lining" :
                            "Kampana Balata" }}</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="container mt-1 product-contain first-banner-color">
            <div class="row align-items-center justify-content-center p-0 pr-5">
                <div class="col-12 col-md-7 p-5">
                    <div class="d-flex flex-column p-5">
                        <h1 class="detail-h1" v-if="!isEnglish">Kampana Balata Nedir?</h1>
                        <h1 class="detail-h1" v-else>What is Drum Pad?</h1>
                        <div class="hr-blue-product"></div>
                        <p class="mt-3" v-if="!isEnglish">
                            <b>Kampana balataları</b>, sürtünmeli frenlerde kullanılan ve araç tekerlekleriyle
                            bağlantılı disk veya kampanalara sürtünme yoluyla fren kuvvetini ileten önemli
                            bileşenlerdir. Temelde, dönen bir diske veya kampanaya balata tarafından uygulanan basınç,
                            <b>disk ve balata</b> ara yüzeyinde temas sağlar. Bu temas, kinetik enerjiyi sürtünme işine
                            dönüştürerek diskin hızını yavaşlatır veya durdurur
                        </p>
                        <p class="mt-3" v-if="isEnglish">
                            Drum linings are important components used in friction brakes that transmit braking force through friction to the disc or drum associated with the vehicle wheels. Basically, the pressure applied by the pad to a rotating disk or drum makes contact at the disk and pad interface. This contact converts kinetic energy into frictional work, slowing or stopping the speed of the disk.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-5 p-0">
                    <div class="d-flex justify-content-end">
                        <img src="/images/kampana-balata-nedir.png" alt="Kampana Balata Nedir?" style="width: 50%;">
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-5 mb-5">
            <div id="menu-row" class="container-fluid">
                <div class="row">
                    <!-- Menu Başlangıç  -->
                    <!-- <ViewMenu /> -->
                    <ViewMenu />
                    <!-- Menu Başlangıç  -->

                    <ViewContent :details="details" :second_tab="second_tab" :first_tab="first_tab"
                        :tab_is_open="tab_is_open" />
                </div>
                <div class="col-12 pb-5 pt-5">
                    <OtherCategories :slider_items="slider_content" />
                </div>
            </div>
        </section>

        <section class="container-fluid"></section>
    </div>
</template>

<script>
import ViewMenu from "../ViewMenu";
import ViewContent from "../ViewContent";
import OtherCategories from "../OtherCategories";

export default {
    name: "AbsTekerlekHizSensoru",
    props: [
        "details",
        "second_tab",
        "first_tab",
        "mobile-content",
        "slider_content",
        "tab_is_open"

    ],

    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isEnglish: isEnglish,
        };
    },
    components: { ViewMenu, ViewContent, OtherCategories },
};
</script>

<style lang="scss" scoped></style>
