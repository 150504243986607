<template>
    <div class="news-detail pt-lg-0 pt-5 pb-lg-25 pb-sm-7 pb-20">

        <section class="">
            <div class="container">
                <div class="row">
                    <div class="d-flex justify-content-start align-items-center gap-2 flex-wrap">
                        <a href="/" class="text-decoration-none mr-1" style="color: black;"> {{ isEnglish ? "Home"
                            : "Anasayfa" }} </a>
                        <span style="font-size: 30px;" class="mr-1">></span>
                        <a href="products" class="text-decoration-none text-body"> {{ isEnglish ? "Products" :
                            "Ürünler" }} </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'transmission-group' : 'sanziman-grubu'"
                            class="text-decoration-none text-body mr-1">{{ isEnglish ? "Transmission Group" :
                                "Şanzıman Grubu" }}
                        </a>
                        <span style="font-size: 30px" class="mr-1">></span>
                        <a :href="isEnglish ? 'clutch-bearing' : 'debriyaj-rulmani'"
                            class="text-decoration-none text-danger">{{
                                isEnglish ? "Clutch Bearing" :
                                    "Debriyaj Rulmanı" }}</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="container mt-1 product-contain first-banner-color">
            <div class="row align-items-center justify-content-center p-0 pr-5">
                <div class="col-12 col-md-7 p-5">
                    <div class="d-flex flex-column p-5">
                        <h1 class="detail-h1" v-if="!isEnglish">Debriyaj Rulmanı </h1>
                        <h1 class="detail-h1" v-else>Clutch Bearing</h1>
                        <div class="hr-blue-product"></div>
                        <p class="mt-3" v-if="!isEnglish">
                            Debriyaj rulmanı, diğer adıyla debriyaj merkezi veya itiş yatağı olarak da bilinir, debriyaj
                            mekanizmasında kullanılan ve debriyaj diskinin hareket etmesini sağlayan bir parçadır.
                            Debriyaj rulmanı, debriyaj pedalının basılmasıyla veya bırakılmasıyla debriyaj baskı plakası
                            üzerinde baskı uygular veya baskıyı kaldırır. Bu sayede motor gücünün şanzımana iletilmesini
                            veya kesilmesini sağlar.
                        </p>
                        <p class="mt-3" v-if="isEnglish">
                            <b>Clutch Bearing</b>, also known as the <b>Clutch master</b> or <b>thrust bearing</b>, is a component used in the clutch mechanism that enables the movement of the clutch disc. The clutch bearing applies or releases pressure on the clutch pressure plate when the clutch pedal is pressed or released. This action allows for the transmission of engine power to the gearbox or cuts off the power transmission.<br>
                            It plays a crucial role in engaging and disengaging the clutch, ensuring smooth gear changes and proper power transfer between the engine and transmission. The clutch bearing, typically located between the clutch fork and the pressure plate, ensures the proper functioning of the clutch system.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-5 p-0">
                    <div class="d-flex justify-content-end">
                        <img src="/images/debriyaj-rulmani.png" alt="Debriyaj Rulmani " style="width: 50%;">
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-5 mb-5">
            <div id="menu-row" class="container-fluid">
                <div class="row">
                    <!-- Menu Başlangıç  -->
                    <!-- <ViewMenu /> -->
                    <ViewMenu />
                    <!-- Menu Başlangıç  -->


                    <ViewContent :details="details" :second_tab="second_tab" :first_tab="first_tab"
                        :tab_is_open="tab_is_open" />

                </div>
                <div class="col-12 pb-5 pt-5">
                    <OtherCategories :slider_items="slider_content" />
                </div>
            </div>
        </section>

        <section class="container-fluid"></section>
    </div>
</template>

<script>
import ViewMenu from '../ViewMenu';
import ViewContent from "../ViewContent"
import OtherCategories from "../OtherCategories"


export default {
    name: "AbsTekerlekHizSensoru",
    props: [
        "details",
        "second_tab",
        "first_tab",
        "mobile-content",
        "slider_content",
        "tab_is_open"
    ],
    data() {
        const currentUrl = window.location.pathname; // URL'in yol kısmını alır
        const firstSegment = currentUrl.split('/')[1]; // İlk segmenti alır (örneğin, 'en')
        const isEnglish = firstSegment === 'en'; // İlk segment 'en' ise true döner
        return {
            isEnglish: isEnglish,
        };
    },
    components: { ViewMenu, ViewContent, OtherCategories }
}
</script>

<style lang="scss" scoped></style>